<template>
  <div :style="{ height: props.tableHeight, position: 'relative' }">
    <el-table
      ref="tableListRef"
      v-loading="props.tableLoading"
      :data="props.tableData.list"
      border
      stripe
      height="calc(100% - 54px)"
      row-key="contactId"
      class="elv-contact-table"
      header-cell-class-name="elv-contact-table-header__cell"
      header-row-class-name="elv-contact-table-header"
      row-class-name="elv-contact-table-row"
      cell-class-name="elv-contact-table-row__cell"
      @row-click="onCellClicked"
    >
      <template #empty>
        <div class="elv-contact-unnamed-table-empty">
          <img src="@/assets/img/reports/table-built.png" alt="empty" />
          <p>
            No unnamed counterparties. <span>Please</span>&nbsp;
            <router-link :to="{ name: 'entity-accounts-treasury' }">add account and import transactions.</router-link>
          </p>
        </div>
      </template>
      <el-table-column type="selection" width="40" :disabled="true" reserve-selection />
      <el-table-column :label="t('report.Address/Account')">
        <template #default="{ row }">
          <AddressCell
            :params="{ data: row, value: { cellName: 'Address/Bank Account', tableType: 'CounterParty' } }"
          />
        </template>
      </el-table-column>
      <el-table-column :label="t('common.operation')" width="300" class-name="elv-contact-table-row__operation">
        <template #default="{ row }">
          <div @click="onCreateNew(row)">
            <SvgIcon name="add-default" width="20" height="20" fill="#7596EB" />
            {{ t('button.createNewCounterparty') }}
          </div>
          <div @click="onAddToCounterparty(row)">
            <SvgIcon name="add-default" width="20" height="20" fill="#7596EB" />
            {{ t('button.addToCounterparty') }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <elv-pagination
      class="elv-transactions-list-pagination"
      size-popper-class="elv-transactions-pagination__sizes-popper"
      :limit="props.contactsParams.limit"
      :current-page="props.contactsParams.page"
      :total="props.tableData.total"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />
  </div>

  <AddToCounterpartyDialog ref="addToCounterpartyDialogRef" :currentData="overlayDrawerData" @resetList="resetList" />

  <CounterpartyDialog ref="counterpartyDialogRef" :currentData="overlayDrawerData" model="add" @resetList="resetList" />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { useEntityStore } from '@/stores/modules/entity'
import AddressCell from '@/components/Reports/Cell/AddressCell.vue'
import AddToCounterpartyDialog from './AddToCounterpartyDialog.vue'
import CounterpartyDialog from '../../../components/Counterparty/CounterpartyDialog.vue'

const props = defineProps({
  tableData: {
    type: Object,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  tableHeight: {
    type: String,
    required: true
  },
  contactsParams: {
    type: Object,
    required: true
  }
})

const { t } = useI18n()
const entityStore = useEntityStore()
const emit = defineEmits(['resetList', 'onChangePage', 'onChangePageSize'])

const tableListRef = useTemplateRef('tableListRef')
const counterpartyDialogRef = useTemplateRef('counterpartyDialogRef')
const addToCounterpartyDialogRef = useTemplateRef('addToCounterpartyDialogRef')
const overlayDrawerData = ref({})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

const resetList = () => {
  emit('resetList')
}

const onAddToCounterparty = (row: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.counterparty?.create
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  overlayDrawerData.value = row
  addToCounterpartyDialogRef.value?.onCheckAddToDialog()
}

const onCreateNew = (row: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.counterparty?.create
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  overlayDrawerData.value = row
  counterpartyDialogRef.value?.onCheckCounterpartyDialog()
}

// eslint-disable-next-line no-unused-vars
const onCellClicked = async (row: any, column: any, e: any) => {
  try {
    overlayDrawerData.value = {}
    if (
      e.target?.className !== 'elv-financials-cell-address-main__copy' &&
      e.target?.className !== 'elv-financials-cell-address-main__address'
    ) {
      if (
        ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
        !currentEntityPermission.value?.transaction?.viewDetail
      ) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      overlayDrawerData.value = row
    }
  } catch (error: any) {
    ElMessage.error(error.message)
  }
}

defineExpose({
  ref: tableListRef
})
</script>

<style lang="scss">
.elv-contact-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  --el-fill-color-lighter: #f9fafb;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;
    height: 100% !important;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    height: 616px;

    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .elv-contact-table-header {
    background: #eef4fb;

    .elv-contact-table-header__cell {
      background: #eef4fb;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;
      padding: 0px;

      &:last-child {
        border-right: 0px;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .elv-contact-table-row__cell {
    padding: 0;
    height: 42px;

    &:last-child {
      border-right: 0px;
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;

      .elv-contact-table-row__cell-text {
        font-weight: 500;

        &.is-empty {
          color: #aaafb6;
        }
      }
    }

    &.elv-contact-table-row__operation {
      .cell {
        display: flex;
        align-items: center;
        gap: 24px;

        div {
          display: flex;
          align-items: center;
          color: #2f63eb;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 13px;
          cursor: pointer;
        }
      }
    }

    &.el-table-column--selection {
      .cell {
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .el-table__footer-wrapper {
    .el-table__footer {
      height: 42px;

      .cell.elv-contact-table-row__total {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .cell.elv-contact-table-row__transaction {
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;

        p {
          color: #838d95;
          font-family: 'Barlow';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: none;
        }
      }
    }
  }

  .el-table__empty-block {
    transform: translateY(-20%);

    .elv-contact-unnamed-table-empty {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 64px;
        height: 64px;
        box-sizing: border-box;
        margin-bottom: 13px;
      }

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        span {
          margin-left: 8px;
          color: #1e2024;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        a {
          color: #2f63eb;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration-line: underline;
        }
      }
    }
  }
}

.elv-transactions-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}
</style>
