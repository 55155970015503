<template>
  <div class="elv-counterparty-unnamed-page-container">
    <ContactScreening tableType="Contact" />

    <ContactUnnamedList
      ref="contactUnnamedListRef"
      :contactsParams="contactsParams"
      :tableHeight="tableHeight"
      :tableData="accountStore.contactList"
      :tableLoading="loading"
      @resetList="resetList"
      @onChangePage="onChangePage"
      @onChangePageSize="onChangePageSize"
    />
  </div>
</template>

<script setup lang="ts">
import ContactScreening from '../components/Screening.vue'
import { useAccountStore } from '@/stores/modules/accounts'
import ContactUnnamedList from './components/ContactUnnamedList.vue'

const route = useRoute()
const accountStore = useAccountStore()

const loading = ref(false)
const contactUnnamedListRef = useTemplateRef('contactUnnamedListRef')
const contactsParams: any = ref({
  page: 1,
  limit: 20
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const tableHeight = computed(() => {
  return `calc(100% - 46px)`
})

const getContactsTableData = async () => {
  try {
    loading.value = true
    await accountStore.fetchContactList(entityId.value, contactsParams.value)
    contactUnnamedListRef.value?.ref?.setScrollTop(0)
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

const resetList = () => {
  contactsParams.value.limit = 20
  contactsParams.value.page = 1
  getContactsTableData()
}

const onChangePageSize = (limit: number) => {
  contactsParams.value.limit = limit
  contactsParams.value.page = 1
  getContactsTableData()
}

const onChangePage = (page: number) => {
  contactsParams.value.page = page
  getContactsTableData()
}

watch(
  route,
  async (newValue) => {
    if (newValue.name === 'entity-accounts-contacts-unnamed') {
      accountStore.contactList = { total: 0, list: [] }
      getContactsTableData()
    }
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss" scoped>
.elv-counterparty-unnamed-page-container {
  width: 100%;
  height: calc(100% - 90px);
  position: relative;
}
</style>
